// ////////////////////////////スマホ/タブレット判別
var _ua = (function (u) {
  return {
    Tablet:
      (u.indexOf('windows') != -1 && u.indexOf('touch') != -1) ||
      u.indexOf('ipad') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') == -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1) ||
      u.indexOf('kindle') != -1 ||
      u.indexOf('silk') != -1 ||
      u.indexOf('playbook') != -1,
    Mobile:
      (u.indexOf('windows') != -1 && u.indexOf('phone') != -1) ||
      u.indexOf('iphone') != -1 ||
      u.indexOf('ipod') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') != -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1) ||
      u.indexOf('blackberry') != -1,
  };
})(window.navigator.userAgent.toLowerCase());
// if(_ua.Mobile){}

var osVer;
osVer = 'Android';

var ua = navigator.userAgent.toLowerCase();
var iPadFlag = false;
if (/android|ipod|ipad|iphone|macintosh/.test(ua) && 'ontouchend' in document) {
  iPadFlag = true;
  $('#wrapper').addClass('setTouch');
}
// if (navigator.userAgent.indexOf(osVer)>0){
// }

var breakNum = 768;
var tabletNum = 1024;
// ////////////////////////////////////init
// $(function () {
//   // setScroll();
//   // if ($('#wrapper').hasClass('home')) {
//   //   setWave();
//   // }
//   // ///////////
//   // if (!_ua.Mobile && !_ua.Tablet) {
//   //   $('#wrapper').addClass('desktop');
//   //   if (!$('#wrapper').hasClass('about')) {
//   //     $('html, body').prop({
//   //       scrollTop: 0,
//   //     });
//   //     if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1) {
//   //       // ここにSafari用の記述
//   //     } else {
//   //     }
//   //   }
//   // }
//   // ///////////
// });

$(window).on('load', function () {
  judgeWinSize();
  // setSpan();
  // setAcc();
  // setMegaMenu();
  // startScroll();
  setLoaded();
});

var current_scrollY = 0;

// ////////////////////////////アコーディオン
function setAcc() {
  $('.js-toggle .js-ttl').on('click', function () {
    $(this).parent().toggleClass('active');
    $(this).next().stop().slideToggle('fast');
  });
}

// //////////////////////////////ロード完了
function setLoaded() {
  $('#wrapper,#loading').addClass('loaded');
  loadStart();
}

function loadStart() {
  timer = setTimeout(function () {
    $('#wrapper,#loading').addClass('loadedDone');
  }, 200);
  timer = setTimeout(function () {
    $('#wrapper,#loading').addClass('loadedStart');
  }, 600);
  timer = setTimeout(function () {
    $('#wrapper').addClass('loadEnd');
    setWay();
  }, 2000);
  timer = setTimeout(function () {
    $('#wrapper').addClass('homeStart');
  }, 1200);
  if ($('#wrapper').hasClass('home')) {
    timer = setTimeout(function () {
      $('#wrapper').addClass('homeDelay');
    }, 2200);
  } else {
    timer = setTimeout(function () {
      $('#wrapper').addClass('pagesDelay');
    }, 500);
  }
  // $('body,html').animate({
  //   scrollTop: 0
  // }, 0, 'swing')
}

// ///////waypoint
function setWay() {
  $('.way,.way-slow,.alphaWay,.js-way').waypoint(
    function (direction) {
      var activePoint = $(this.element);
      if (direction === 'down') {
        // scroll down
        activePoint.addClass('is-active');
        timer = setTimeout(function () {
          activePoint.addClass('is-active-after');
        }, 500);
      }
    },
    {
      offset: '70%',
    }
  );
  $('.js-item-way').waypoint(
    function (direction) {
      var activePoint = $(this.element);
      if (direction === 'down') {
        // scroll down
        activePoint.addClass('is-active-item');
        timer = setTimeout(function () {
          activePoint.addClass('is-active-item-after');
        }, 500);
      }
    },
    {
      offset: '70%',
    }
  );
  $('.video-way').waypoint(
    function (direction) {
      var activePoint = $(this.element);
      var videoElem = activePoint.find('video');
      if (direction === 'down') {
        videoElem.get(0).play();
      }
    },
    {
      offset: '70%',
    }
  );
}

// ////////////ウィンドウサイズを判別
function judgeWinSize() {
  var winW = $(window).width();
  if (winW > breakNum) {
    $('#wrapper').addClass('setPc');
  } else {
    $('#wrapper').addClass('setSp');
  }

  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
      if (winW > breakNum) {
        $('#wrapper').addClass('setPc');
        $('#wrapper').removeClass('setSp');
      } else {
        $('#wrapper').addClass('setSp');
        $('#wrapper').removeClass('setPc');
      }
    }, 200);
  });
}

// /////////////////////////////スムーススクロール
function setScroll() {
  var headerHeight = $('header').height();
  // var headerHeight = $('header .col-main').height() + 50;
  // if ($('#wrapper').hasClass('setPc')) {
  //   var headerHeight = 0;
  // } else {
  //   var headerHeight = 100;
  // }
  $('a[href^="#"]').click(function (e) {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    // var position = target.offset().top;

    if ($(this).hasClass('unqNav')) {
      closeFnc();
    }
    setTimeout(function () {
      var position = target.offset().top - headerHeight;
      $('html, body').animate(
        {
          scrollTop: position,
        },
        400,
        'swing'
      );
    }, 100);
    e.preventDefault();
    // $.when(
    //   $('html, body').animate(
    //     {
    //       scrollTop: position,
    //     },
    //     400,
    //     'swing'
    //   ),
    //   e.preventDefault()
    // ).done(function () {
    //   var diff = target.offset().top;
    //   if (diff === position) {
    //   } else {
    //     $('html, body').animate(
    //       {
    //         scrollTop: diff,
    //       },
    //       400,
    //       'swing'
    //     );
    //   }
    // });
  });
}

function startScroll() {
  // ページのURLを取得
  const url = $(location).attr('href'),
    // headerの高さを取得してそれに30px追加した値をheaderHeightに代入
    headerHeight = $('header').outerHeight() + 30;

  // urlに「#」が含まれていれば
  if (url.indexOf('#') != -1) {
    // urlを#で分割して配列に格納
    const anchor = url.split('#'),
      // 分割した最後の文字列（#◯◯の部分）をtargetに代入
      target = $('#' + anchor[anchor.length - 1]),
      // リンク先の位置からheaderHeightの高さを引いた値をpositionに代入
      position = Math.floor(target.offset().top) - headerHeight;
    // positionの位置に移動
    $('html, body').animate({ scrollTop: position }, 500);
  }
  setScroll();
}

// ////////////////////////////////////ランドスケープ判定
var isLandscape = function () {
  if (window.innerHeight > window.innerWidth) {
    jQuery('body').addClass('portrait');
    jQuery('body').removeClass('landscape');
  } else {
    jQuery('body').addClass('landscape');
    jQuery('body').removeClass('portrait');
  }
};

if (_ua.Mobile) {
  jQuery(window).resize(function () {
    isLandscape();
  });
  isLandscape();
}
